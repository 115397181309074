import Grid from '@mui/material/Grid';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { Lead, LeadItem, LeadStatus, LeadsState, ModalType } from '../types/types';
import DeleteForm from './forms/deleteForm';
import HandledForm from './forms/handledForm';
import UnhandledForm from './forms/unhandledForm';

interface Props {
  selectedLead: LeadItem | Lead;
  handleCancel: () => void;
  leadsStatus?: LeadsState;
  singleLead?: boolean;
  refreshTableData?: () => Promise<void>;
}

const LeadsDialog: FC<Props> = ({ handleCancel, selectedLead, leadsStatus, refreshTableData, singleLead }) => {
  const [dialogStatus, setDialogStatus] = useState<ModalType>(leadsStatus === LeadsState.handled ? 'handled' : 'unhandled');
  const navigate = useNavigate();

  function unhandledFormCallback(value: LeadStatus): void {
    if (singleLead && value !== 'noContact') {
      navigate(`/${dialogStatus !== 'delete' ? dialogStatus : '/handled'}`, {
        replace: true
      });
    }
  }

  function handledFormCallback(): void {
    if (singleLead)
      navigate(`/${dialogStatus}`, {
        replace: true
      });
  }

  function deleteFormCallback(): void {
    if (singleLead) {
      navigate(`/${dialogStatus !== 'delete' ? dialogStatus : 'handled'}`, {
        replace: true
      });
    }
  }

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        {dialogStatus === 'unhandled' && (
          <UnhandledForm
            leadId={selectedLead.id}
            setDialogStatus={setDialogStatus}
            leadsStatus={selectedLead?.leadStatus.type}
            callback={unhandledFormCallback}
            closeModal={handleCancel}
            refreshTableData={refreshTableData}
          />
        )}
        {dialogStatus === 'handled' && (
          <HandledForm leadId={selectedLead.id} callback={handledFormCallback} closeModal={handleCancel} refreshTableData={refreshTableData} />
        )}
        {dialogStatus === 'delete' && (
          <DeleteForm leadId={selectedLead.id} callback={deleteFormCallback} closeModal={handleCancel} refreshTableData={refreshTableData} />
        )}
      </Grid>
    </>
  );
};

export default LeadsDialog;
