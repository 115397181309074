import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { ApiError, ToastSeverity, useFetcher, useToast } from 'thermia-react-container';
import { config } from '../config';
import { Lead, LeadConnectionStatusTypeEnum, LeadItems, LeadsState, Visibility } from '../types/types';

const useLeadsService = () => {
  const { fetcher } = useFetcher();
  const { addToast } = useToast();
  const { t } = useTranslation('leads');

  const getHeaders = (): HeadersInit => {
    return {
      'Accept-Language': i18n.language
    };
  };

  const getLeadsList = async (state: LeadsState, skip: number, take: number): Promise<LeadItems> => {
    try {
      const url = `${config.apiUrl}/leads/items/${state}?skip=${skip}&take=${take}`;
      const headers = getHeaders();
      const response = await fetcher<LeadItems>('GET', url, headers);
      return response;
    } catch (error) {
      addToast(t('leads.error.failedToFetchLeads'), ToastSeverity.ERROR);
      return { items: [], totalCount: 0 };
    }
  };

  const getLeadsDataById = async (id: number) => {
    try {
      const url = `${config.apiUrl}/leads/${id}`;
      const headers = getHeaders();
      const response = await fetcher<Lead>('GET', url, headers);
      return response;
    } catch (error) {
      if (error instanceof ApiError) {
        addToast(t('leads.error.failedToFetchLeads'), ToastSeverity.ERROR);
      }
      return null;
    }
  };

  async function updateLeadsStatus(comment: string, status: LeadConnectionStatusTypeEnum, id: number): Promise<boolean> {
    const body = {
      comment: comment,
      status: status
    };
    try {
      const url = `${config.apiUrl}/leads/status/${id}`;
      const headers = getHeaders();
      await fetcher('PATCH', url, headers, body);
      return true;
    } catch (error) {
      if (error instanceof ApiError) {
        addToast(t('leads.error.failedToUpdateLeads'), ToastSeverity.ERROR);
      }
      return false;
    }
  }

  async function setLeadsVisibility(visibility: Visibility, id: number): Promise<boolean> {
    const body = {
      visibility: visibility
    };
    try {
      const url = `${config.apiUrl}/leads/visibility/${id}`;
      const headers = getHeaders();
      await fetcher('PATCH', url, headers, body);
      return true;
    } catch (error) {
      if (error instanceof ApiError) {
        addToast(t('leads.error.failedToUpdateLeads'), ToastSeverity.ERROR);
      }
      return false;
    }
  }

  return {
    getLeadsList,
    getLeadsDataById,
    updateLeadsStatus,
    setLeadsVisibility
  };
};

export default useLeadsService;
