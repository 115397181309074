import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'thermia-react-container';
import LeadsTable from '../components/leadsTable';
import { LeadsState } from '../types/types';

const Handled: FC = () => {
  const { t } = useTranslation('leads');

  useEffect(() => {
    document.title = t('leads.general.thermiaLeads');
  }, [t]);

  return (
    <>
      <Title text={`${t('leads.general.handledLeads')}`} />
      <LeadsTable leadsStatus={LeadsState.handled} />
    </>
  );
};

export default Handled;
