import CloseIcon from '@mui/icons-material/Close';
import { DialogContentText, DialogTitle, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormButton, useLoader } from 'thermia-react-container';
import useLeadsService from '../../service/useLeadsService';
import { LeadConnectionStatusTypeEnum } from '../../types/types';

type Props = {
  leadId: number;
  refreshTableData?: () => Promise<void>;
  closeModal: () => void;
  callback: () => void;
};

const DeleteForm: FC<Props> = ({ leadId, refreshTableData, closeModal, callback }) => {
  const { t } = useTranslation('leads');
  const [text, setText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const { isLoading, Loader, showLoader, hideLoader } = useLoader();
  const { updateLeadsStatus } = useLeadsService();

  async function sendDeleteRequest() {
    if (isLoading) {
      return;
    }
    if (text === '') {
      hideLoader();
      setError(true);
      return;
    } else {
      showLoader();
      const success = await updateLeadsStatus(text, LeadConnectionStatusTypeEnum.customerNotReachable, leadId);
      if (success) {
        if (refreshTableData) {
          await refreshTableData();
        }
        callback();
        closeModal();
        setText('');
      }
    }
    hideLoader();
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <CloseIcon
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            cursor: 'pointer'
          }}
          onClick={() => closeModal()}
        />

        <DialogTitle style={{ minWidth: '100%', fontWeight: '600' }}>{t('leads.general.question.deleteLead')}</DialogTitle>
        <DialogContentText style={{ width: '100%', padding: '0px 20px' }}>{t('leads.general.question.deleteLeadContentText')}</DialogContentText>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            width: '100%',
            padding: '20px 20px 20px 20px'
          }}>
          {isLoading && <Loader />}
          <TextField
            rows={4}
            variant="outlined"
            fullWidth
            error={error}
            multiline
            placeholder={t('leads.general.explainReason')}
            onChange={(e) => {
              setError(false);
              setText(e.target.value);
            }}
            helperText={error ? t('leads.general.explainReason') : ''}
          />
          <div style={{ gap: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <FormButton
              disabled={isLoading ? true : false}
              title={t('leads.general.ok')}
              variant="contained"
              style={{
                width: '120px',
                borderRadius: '11px'
              }}
              onClick={() => sendDeleteRequest()}
            />
            <FormButton
              disabled={isLoading ? true : false}
              title={t('leads.general.button.cancel')}
              variant="outlined"
              style={{
                width: '120px',
                borderRadius: '11px'
              }}
              onClick={() => closeModal()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteForm;
