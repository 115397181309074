import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormButton, theme, useLoader } from 'thermia-react-container';
import useLeadsService from '../../service/useLeadsService';
import { LeadConnectionStatusTypeEnum, LeadStatus, ModalType } from '../../types/types';

type Props = {
  leadId: number;
  closeModal: () => void;
  setDialogStatus: React.Dispatch<React.SetStateAction<ModalType>>;
  leadsStatus?: LeadConnectionStatusTypeEnum;
  callback: (value: LeadStatus) => void;
  refreshTableData?: () => Promise<void>;
};

const UnhandledForm: FC<Props> = ({ refreshTableData, closeModal, setDialogStatus, leadsStatus, callback, leadId }) => {
  const { t } = useTranslation('leads');
  const { Loader, showLoader, hideLoader, isLoading } = useLoader();
  const { updateLeadsStatus } = useLeadsService();

  async function sendUnhandled(value: LeadStatus) {
    if (!isLoading) {
      showLoader();
      if (value === 'noContact') {
        setDialogStatus('delete');
      } else {
        let status: LeadConnectionStatusTypeEnum = LeadConnectionStatusTypeEnum.closed;
        if (value === 'hasContacted') {
          status = LeadConnectionStatusTypeEnum.closed;
        } else if (value === 'hasStarted') {
          status = LeadConnectionStatusTypeEnum.customerContact;
        }

        const success = await updateLeadsStatus(LeadConnectionStatusTypeEnum.customerContact, status, leadId);
        if (success) {
          if (refreshTableData) {
            // table only exits if we are in the table view
            await refreshTableData();
          }
          callback(value);
          closeModal();
        }
      }
    }
    hideLoader();
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <CloseIcon
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            cursor: 'pointer'
          }}
          onClick={() => closeModal()}
        />

        <DialogTitle style={{ minWidth: '100%', fontWeight: '600' }}>{t('leads.general.question.handleLead')}</DialogTitle>
        <div
          style={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '0 20px 20px 20px',
            gap: '15px',
            zIndex: 0
          }}>
          <FormButton
            title={t('leads.general.button.hasContacted')}
            variant="contained"
            disabled={isLoading ? true : false}
            style={{
              padding: '10px',
              fontSize: '15px',
              border: '1px solid #DADADA',
              borderRadius: '11px',

              minWidth: '100%',
              backgroundColor: isLoading ? theme.palette.primary.contrastText : theme.palette.primary.light
            }}
            onClick={() => {
              sendUnhandled('hasContacted');
            }}
          />
          {(leadsStatus === LeadConnectionStatusTypeEnum.sentToReseller || leadsStatus === LeadConnectionStatusTypeEnum.newTryWithCustomer) && (
            <FormButton
              title={t('leads.general.button.hasStarted')}
              variant="contained"
              disabled={isLoading ? true : false}
              style={{
                padding: '20px',
                fontSize: '15px',
                border: '1px solid #DADADA',
                borderRadius: '11px',
                minWidth: '100%',
                backgroundColor: isLoading ? theme.palette.primary.contrastText : theme.palette.primary.light
              }}
              onClick={() => {
                sendUnhandled('hasStarted');
              }}
            />
          )}
          {leadsStatus === LeadConnectionStatusTypeEnum.customerContact && (
            <FormButton
              variant="contained"
              title={t('leads.general.button.noContact')}
              disabled={isLoading ? true : false}
              style={{
                padding: '20px',
                lineHeight: '1',
                fontSize: '15px',
                border: '1px solid #DADADA',
                borderRadius: '11px',
                minWidth: '100%',
                backgroundColor: isLoading ? theme.palette.primary.contrastText : theme.palette.primary.light
              }}
              onClick={() => {
                sendUnhandled('noContact');
              }}
            />
          )}
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default UnhandledForm;
