import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormButton, theme, useLoader } from 'thermia-react-container';
import useLeadsService from '../../service/useLeadsService';
import { LeadCompletionStatus, Visibility } from '../../types/types';

type Props = {
  leadId: number;
  refreshTableData?: () => Promise<void>;
  closeModal: () => void;
  callback: () => void;
};

const HandledForm: FC<Props> = ({ leadId, callback, refreshTableData, closeModal }) => {
  const { Loader, showLoader, hideLoader, isLoading } = useLoader();
  const { t } = useTranslation('leads');
  const { setLeadsVisibility } = useLeadsService();

  async function sendHandled(value: LeadCompletionStatus) {
    if (!isLoading) {
      showLoader();
      let visibility = Visibility.NotVisibleDeal;
      if (value === 'customerBought') {
        visibility = Visibility.NotVisibleDeal;
      } else if (value === 'customerNotDecided') {
        visibility = Visibility.NotVisibleUnsure;
      } else if (value === 'customerNotBought') {
        visibility = Visibility.NotVisibleNoDeal;
      }
      const success = await setLeadsVisibility(visibility, leadId);
      if (success) {
        callback();
        closeModal();
      }
      if (refreshTableData) {
        await refreshTableData();
      }
    }
    hideLoader();
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <CloseIcon
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            cursor: 'pointer'
          }}
          onClick={() => closeModal()}
        />

        <DialogTitle style={{ minWidth: '100%', fontWeight: '600' }}>{t('leads.general.question.handleLead')}</DialogTitle>
        <div
          style={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '0 20px 20px 20px',
            gap: '15px'
          }}>
          <FormButton
            title={t('leads.general.button.customerBought')}
            variant="contained"
            disabled={isLoading ? true : false}
            style={{
              letterSpacing: '0px',
              padding: '8px',
              fontSize: '14px',
              border: '1px solid #DADADA',
              borderRadius: '11px',
              minWidth: '100%',
              backgroundColor: isLoading ? theme.palette.primary.contrastText : theme.palette.primary.light
            }}
            onClick={() => {
              sendHandled('customerBought');
            }}
          />
          <FormButton
            title={t('leads.general.button.customerNotDecided')}
            variant="contained"
            disabled={isLoading ? true : false}
            style={{
              letterSpacing: '0px',
              padding: '8px',
              maxWidth: '310px',
              fontSize: '14px',
              border: '1px solid #DADADA',
              borderRadius: '11px',
              minWidth: '100%',
              backgroundColor: isLoading ? theme.palette.primary.contrastText : theme.palette.primary.light
            }}
            onClick={() => {
              sendHandled('customerNotDecided');
            }}
          />
          <FormButton
            variant="contained"
            title={t('leads.general.button.customerNotBought')}
            disabled={isLoading ? true : false}
            style={{
              letterSpacing: '0px',
              maxWidth: '310px',
              padding: '8px',
              border: '1px solid #DADADA',
              fontSize: '14px',
              borderRadius: '11px',
              minWidth: '100%',
              backgroundColor: isLoading ? theme.palette.primary.contrastText : theme.palette.primary.light
            }}
            onClick={() => {
              sendHandled('customerNotBought');
            }}
          />
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default HandledForm;
