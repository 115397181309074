import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { FormButton, theme } from 'thermia-react-container';

type Props = {
  selection: string;
  setOpen: (value: boolean) => void;
};

const SingleLeadHandlers = (props: Props) => {
  const { selection, setOpen } = props;

  const { t } = useTranslation('leads');

  function unHandledButtons() {
    return (
      <ButtonDiv>
        <FormButton
          variant="contained"
          title={t('leads.general.button.handle')}
          style={{
            maxWidth: '310px',
            lineHeight: '1',
            padding: '8px',
            fontSize: '15px',
            height: '50px',
            minWidth: '150px',
            backgroundColor: theme.palette.primary.light
          }}
          onClick={() => {
            setOpen(true);
          }}
        />
      </ButtonDiv>
    );
  }

  const handledButtons = () => {
    return (
      <ButtonDiv>
        <FormButton
          title={t('leads.general.button.handle')}
          variant="contained"
          style={{
            letterSpacing: '0px',
            padding: '8px',
            minWidth: '150px',
            fontSize: '15px',
            lineHeight: '1',
            height: '50px'
          }}
          onClick={() => {
            setOpen(true);
          }}
        />
      </ButtonDiv>
    );
  };

  return (
    <div className="no-print">
      <style>
        {`
          @media print {
            .no-print {
              display: none;
              }
            }
          `}
      </style>
      {selection === 'handled' ? handledButtons() : unHandledButtons()}
    </div>
  );
};
export default SingleLeadHandlers;

const ButtonDiv = styled('div')(({ theme }) => ({
  padding: '30px 30px',
  width: '100%',
  flexWrap: 'wrap',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignContent: 'center',
  color: '#EDF3FA',
  backgroundColor: '#EDF3FA',
  gap: '15px'
}));
