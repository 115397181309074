import FolderSharedIcon from '@mui/icons-material/FolderShared';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useTranslation } from 'react-i18next';
import { Navigate, Route } from 'react-router-dom';
import { TrcApp } from 'thermia-react-container';
import { setConfig } from './config';
import Handled from './pages/handled';
import Leads from './pages/leads';
import Unhandled from './pages/unhandled';

const App = () => {
  const { t } = useTranslation('leads');

  document.title = 'Thermia Leads';

  return (
    <TrcApp
      configPath="/config/config.json"
      setConfig={setConfig}
      title="Leads"
      requiredPermissions={['sm_le']}
      contentMaxWidth={'xl'}
      routes={
        <>
          <Route path="/" element={<Navigate to={`/unhandled`} replace />} />
          <Route path="/handled" element={<Handled />} />
          <Route path="/unhandled" element={<Unhandled />} />
          <Route path="/leads/:id" element={<Leads />} />
        </>
      }
      tabs={[
        {
          icon: GroupAddIcon,
          label: t('leads.general.unhandled'),
          path: 'unhandled'
        },
        {
          icon: FolderSharedIcon,
          label: t('leads.general.handled'),
          path: 'handled'
        }
      ]}
    />
  );
};

export default App;
