import PrintIcon from '@mui/icons-material/Print';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog, Title, useLoader } from 'thermia-react-container';
import logo from '../components/assets/thermia.png';
import LeadsForm from '../components/leadsDialog';
import SingleLeadHandlers from '../components/singleLeadHandlers';
import useLeadsService from '../service/useLeadsService';
import { Lead, LeadConnectionStatusTypeEnum, LeadsState } from '../types/types';

type RichTextComponentProps = {
  htmlContent: string;
};

const emptyLead: Lead = {
  id: 0,
  qualifyDate: '',
  source: '',
  leadStatus: { type: LeadConnectionStatusTypeEnum.closed, text: '' },
  richText: ''
};

const Leads = () => {
  const { id } = useParams();
  const { getLeadsDataById } = useLeadsService();
  const navigate = useNavigate();
  const [ready, setReady] = React.useState(false);
  const [leadsData, setLeadsData] = useState<Lead>(emptyLead);
  const idToNumber = Number(id);
  const { t, i18n } = useTranslation('leads');
  const { isLoading, Loader, showLoader, hideLoader } = useLoader();
  const [open, setOpen] = useState(false);
  const checkIfHandled = leadsData?.leadStatus?.type === LeadConnectionStatusTypeEnum.closed ? true : false;

  const fetchLead = useCallback(async () => {
    const data = await getLeadsDataById(idToNumber);
    if (data) {
      setLeadsData(data);
      document.title = `Thermia Lead - ${moment(data.qualifyDate, moment.ISO_8601).format('YYYY-MM-DD')}  ${data.source} - ${data.id}`;
    } else {
      navigate('/', {
        replace: true
      });
    }
  }, [getLeadsDataById, idToNumber, navigate]);

  const updateLanguage = useCallback(async () => {
    showLoader();
    await fetchLead();
    hideLoader();
  }, [fetchLead, hideLoader, showLoader]);

  useEffect(() => {
    i18n.on('languageChanged', updateLanguage);
    return () => {
      i18n.off('languageChanged', updateLanguage);
    };
  }, [i18n, updateLanguage]);

  const RichTextComponent: React.FC<RichTextComponentProps> = ({ htmlContent }) => {
    return <StyledRichText dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };
  const qualifyDateMoment = moment(leadsData.qualifyDate, moment.ISO_8601);

  useEffect(() => {
    async function fetchData() {
      await fetchLead();
      setReady(true);
    }
    if (!ready) {
      fetchData();
    }
  }, [fetchLead, ready]);

  return (
    <div style={{ position: 'relative' }}>
      <style>
        {`
@media print {
    header {
    opacity: 0;
  }
  .print-content {
    display: flex !important;
    height: auto;
    flex-direction: column;
  },
  .no-print {
    display: none !important;
  },
  }
`}
      </style>
      {isLoading && <Loader />}
      <Dialog id={'single-lead'} open={open}>
        <LeadsForm
          selectedLead={leadsData}
          handleCancel={() => setOpen(false)}
          singleLead={true}
          leadsStatus={checkIfHandled ? LeadsState.handled : LeadsState.unhandled}
        />
      </Dialog>
      <div className="no-print">
        <Title text={t('leads.general.lead')} />
      </div>
      {ready && (
        <>
          <div className="no-print" style={{ fontSize: '40', cursor: 'pointer', position: 'absolute', top: '0', right: '0' }}>
            <Button variant="contained" color="primary" onClick={() => window.print()}>
              <PrintIcon style={{ color: 'white', marginRight: '10px' }} />
              {t('leads.general.print')}
            </Button>
          </div>
        </>
      )}

      {ready ? (
        <div className="print-content">
          <div
            className="print-content"
            style={{
              display: 'none'
            }}>
            <StyledPrintDiv>
              <img src={logo} alt="Thermia" style={{ marginRight: 'auto', width: '75px', height: '75px' }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  wordBreak: 'break-all'
                }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '40px' }}>
                  <p>Thermia AB</p>
                  <p>Box 950</p>
                  <p>671 29 Arvika</p>
                  <p>Sweden</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '30px' }}>
                  <p>{t('leads.general.phone')}: +46 570 813 00</p>
                  <p>{t('leads.general.email')}: info@thermia.com</p>
                  <p>www.thermia.com</p>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                  <p>Org. SE556269-6483</p>
                  <p>Vat. SE556269648301</p>
                </div>
              </div>
            </StyledPrintDiv>
          </div>
          <StyledHeader>
            <p>
              <span>{t('leads.general.source')}:</span> {leadsData.source}
            </p>
            <p>
              <span>{t('leads.general.qualifyDate')}:</span> {qualifyDateMoment.format('YYYY-MM-DD')}
            </p>
            <p>
              <span>{t('leads.general.status')}:</span> {leadsData.leadStatus?.text}
            </p>
          </StyledHeader>
          <StyledDiv>
            <RichTextComponent htmlContent={leadsData?.richText} />
          </StyledDiv>

          <SingleLeadHandlers setOpen={setOpen} selection={checkIfHandled ? 'handled' : 'unhandled'} />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Leads;

const StyledPrintDiv = styled('div')(({ theme }) => ({
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  maxWidth: '1920px',
  paddingBottom: '20px',
  borderBottom: '1px solid lightgrey',

  '& div': {
    flex: 1,
    flexWrap: 'nowrap',
    display: 'flex',
    fontSize: '16px',
    '& p': {
      wordWrap: 'break-all',
      margin: '0',
      color: theme.palette.text.secondary,
      fontSize: '12px'
    }
  }
}));

const StyledRichText = styled('div')(({ theme }) => ({
  width: '100%',
  flexWrap: 'wrap',
  display: 'flex',
  justifyContent: 'flex-start',
  alignContent: 'center',
  flexDirection: 'row',
  fontSize: '16px',
  flex: 1,
  '& section': {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '33%',
    maxWidth: '33%'
  },
  '& p': {
    margin: '3px 40px 10px 0px',
    color: theme.palette.text.secondary,
    borderWidth: '0 0 2px',
    borderStyle: 'solid',
    fontSize: '16px',
    padding: '2px 0',
    borderColor: 'lightgrey',
    wordWrap: 'break-word'
  },
  '& h2': {
    margin: '0',
    color: theme.palette.text.primary,
    fontSize: '15px'
  },
  '& .onsite-phone': {
    margin: '0',
    pointerEvents: 'none',
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    fontSize: '16px'
  }
}));

const StyledDiv = styled('div')(() => ({
  padding: '30px 40px',
  maxWidth: '1920px',
  width: '100%',
  flexWrap: 'wrap',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#EDF3FA',
  justifyContent: 'center',
  alignContent: 'center',
  fontSize: '16px',
  '@media print': {
    backgroundColor: 'none',
    border: 'none',
    boxShadow: 'none',
  }
}));

const StyledHeader = styled('div')(({ theme }) => ({
  maxWidth: '1920px',
  flexWrap: 'wrap',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '#EDF3FA',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '16px',
  gap: '40px',
  marginBottom: '8px',
  '@media print': {
    backgroundColor: 'none',
    border: 'none',
    boxShadow: 'none',
    marginTop: '20px',
  },
  '& p': {
    minWidth: 'fit-content',
    gap: '10px',
    color: theme.palette.text.secondary,
    fontSize: '16px'
  },
  '& span': {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    marginRight: '3px'
  }
}));
