export type Lead = {
  id: number;
  source: string | null;
  leadStatus: LeadConnectionStatus;
  qualifyDate: string | null;
  richText: string;
};

export enum LeadsState {
  unhandled = 'Unhandled',
  handled = 'Handled'
}

export type LeadItems = {
  totalCount: number;
  items: LeadItem[];
};

export type LeadItem = {
  id: number;
  source: string;
  name: string;
  city: string;
  type: string | null;
  qualifyDate: string | null;
  leadStatus: LeadConnectionStatus;
};

export type LeadConnectionStatus = {
  type: LeadConnectionStatusTypeEnum;
  text: string | null;
};

export type ModalType = 'delete' | 'handled' | 'unhandled';

export type LeadStatus = 'hasContacted' | 'hasStarted' | 'noContact';

export enum LeadConnectionStatusTypeEnum {
  sentToReseller = 'SentToReseller',
  newTryWithCustomer = 'NewTryWithCustomer',
  customerNotReachable = 'CustomerNotReachable',
  customerContact = 'CustomerContact',
  closed = 'Closed'
}

export type LeadCompletionStatus = 'customerBought' | 'customerNotDecided' | 'customerNotBought';

export enum Visibility {
  NotVisibleDeal = 'NotVisibleDeal',
  NotVisibleUnsure = 'NotVisibleUnsure',
  NotVisibleNoDeal = 'NotVisibleNoDeal',
  Visible = 'Visible'
}
