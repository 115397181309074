import { TrcConfig } from 'thermia-react-container';

type ApplicationConfig = TrcConfig & {
  // application specific configuration
  // ...
  // currently empty
};

let config: ApplicationConfig;

const setConfig = (newConfig: ApplicationConfig) => {
  config = newConfig;
};

export { config, setConfig };
