import EmailIcon from '@mui/icons-material/Email';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { Typography, styled } from '@mui/material';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { Dialog, FormButton, Table, TablePagination, TableSort, theme } from 'thermia-react-container';
import useLeadsService from '../service/useLeadsService';
import { LeadConnectionStatusTypeEnum, LeadItem, LeadsState } from '../types/types';
import LeadsDialog from './leadsDialog';

type CompletedLeadsProps = {
  leadsStatus: LeadsState;
};

const LeadsTable: FC<CompletedLeadsProps> = ({ leadsStatus }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('leads');
  const navigate = useNavigate();
  const [selectedLead, setSelectedLead] = useState<LeadItem>();

  const { getLeadsList } = useLeadsService();

  const fetchData = async (
    pagination: TablePagination | null,
    search: string | null,
    sort: TableSort | null
  ): Promise<{ data: any[]; totalCount: number }> => {
    const response = await getLeadsList(leadsStatus, pagination!.skip, pagination!.take);
    const items = response.items.map((lead) => ({
      qualifyDate: moment(lead.qualifyDate, moment.ISO_8601).format('YYYY-MM-DD'),
      source: lead.source ?? '',
      name: lead.name ?? '',
      type: lead.type ?? '',
      city: lead.city ?? '',
      leadStatus: lead.leadStatus ?? { text: '', value: '' },
      id: lead.id ?? ''
    }));
    return {
      data: items,
      totalCount: response.totalCount
    };
  };

  const onClick = (value: LeadItem) => {
    navigate(`/leads/${value.id}`);
  };

  const tableMethods = {
    refreshData: () => Promise.resolve() // function will be set by the table
  };

  const refreshTableData = async () => {
    await tableMethods.refreshData();
  };

  function getHandledIcon(data: LeadConnectionStatusTypeEnum) {
    switch (data) {
      case LeadConnectionStatusTypeEnum.customerContact:
        return <LocalPhoneIcon style={{ marginRight: 5 }} />;
      case LeadConnectionStatusTypeEnum.sentToReseller:
        return <EmailIcon style={{ marginRight: 5 }} />;
      case LeadConnectionStatusTypeEnum.newTryWithCustomer:
        return <ForwardToInboxIcon style={{ marginRight: 5 }} />;
      case LeadConnectionStatusTypeEnum.customerNotReachable:
        return <ReportGmailerrorredIcon style={{ marginRight: 5 }} />;
      default:
        return null;
    }
  }

  const columns: Column<LeadItem>[] = useMemo(
    () => [
      {
        accessor: 'qualifyDate',
        Header: t('leads.general.qualifyDate'),
        Cell: (prop) => (
          <>{prop.row.original.qualifyDate !== '' ? moment(prop.row.original.qualifyDate, moment.ISO_8601).format('YYYY-MM-DD') : '-'}</>
        )
      },
      {
        accessor: 'name',
        Header: t('leads.general.leadName'),
        Cell: (prop) => (
          <>
            {prop.row.original.name!.split(',').map((leadName, index) => (
              <StyledContainer key={`${prop.row.id}-${leadName}-${index}`}>
                <span>{leadName}</span>
              </StyledContainer>
            ))}
          </>
        )
      },
      {
        accessor: 'city',
        Header: t('leads.general.city'),
        Cell: (prop) => (
          <>
            <StyledContainer>
              <p>{prop.row.original.city}</p>
            </StyledContainer>
          </>
        )
      },
      {
        accessor: 'source',
        Header: t('leads.general.source'),
        Cell: (prop) => (
          <>
            <StyledContainer>
              <p>{prop.row.original.source}</p>
            </StyledContainer>
          </>
        )
      },
      {
        accessor: 'type',
        Header: t('leads.general.type'),
        Cell: (prop) => (
          <>
            <StyledContainer>
              <p>{prop.row.original.type}</p>
            </StyledContainer>
          </>
        )
      },
      {
        accessor: 'leadStatus',
        Header: t('leads.general.status'),
        Cell: (prop) => (
          <>
            <StyledContainer>
              {getHandledIcon(prop.row.original.leadStatus.type)}
              <p>{prop.row.original.leadStatus.text}</p>
            </StyledContainer>
          </>
        )
      },

      {
        accessor: 'id',
        id: 'menu',
        Header: t('leads.general.handleAction'),
        Cell: (prop) => {
          return (
            <>
              <FormButton
                stopPropagation={true}
                onClick={() => {
                  setSelectedLead(prop.row.original);
                  setOpen(true);
                }}
                style={{ backgroundColor: theme.palette.primary.light, borderRadius: '7px', height: 35 }}
                variant="contained"
                title={t('leads.general.leadsHandleMenu')}
              />
            </>
          );
        }
      }
    ],
    [t]
  );

  return (
    <>
      <Dialog id={'leads-modal'} open={open}>
        <LeadsDialog refreshTableData={refreshTableData} selectedLead={selectedLead!} handleCancel={() => setOpen(false)} leadsStatus={leadsStatus} />
      </Dialog>
      <Table
        columns={columns}
        fetchData={fetchData}
        onClick={onClick}
        pagination={true}
        exposedMethods={tableMethods}
        emptyDataElement={
          <Typography component="div" gutterBottom style={{ textAlign: 'center', marginBottom: 0 }}>
            {t('leads.general.noLeads')}
          </Typography>
        }
      />
    </>
  );
};

export default LeadsTable;

const StyledContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '36px'
}));
